<template>
  <v-card height="100%">
    <v-sheet
      class="rounded"
      v-show="loading == true"
        color="gray lighten-2"
      >
        <v-skeleton-loader
          class="mt-4"
          type="text@2,table-heading,table-thead,table-tbody,table-tfoot"
        ></v-skeleton-loader>
      </v-sheet>
      <v-container
  fluid
      style="height:100%;margin-bottom:80px;"
      :style="{backgroundColor:'#c8d2d8'}">
    <v-data-table
      :headers="headers"
      :items="DataPeminjaman"
      :search="search"
      v-show="loading == false"
      :custom-filter="filter"
      :footer-props="{
        showFirstLastPage: false,
        itemsPerPageText: 'Per Page',
      }"
    >
  <!--Desktop-->
      <template v-slot:top>
        <v-toolbar
        color="#a10115"
        dark
        v-show="device == 'Desktop'">
          <v-toolbar-title class="pencarian_desk">Data Peminjaman</v-toolbar-title>
          
          <v-text-field
          prepend-icon="mdi-magnify"
          class="mt-6"
            v-model="search"
            label="Pencarian"
          ></v-text-field>
        </v-toolbar>
      <!--mobile-->
      <v-toolbar
        color="#a10115"
        elevation="4"
        dark
        v-show="device == 'Mobile'">
          <v-toolbar-title style="font-size:13px" class="pencarian ml-n1">Peminjaman <h4></h4>Kendaraan</v-toolbar-title>
          <v-text-field
            prepend-icon="mdi-magnify"
            class="mt-6 ml-10"
            v-model="search"
            label="Pencarian">
          </v-text-field>
      </v-toolbar>
  
      </template>
      <template v-slot:[`item.id_form_peminjaman`]="{ item }">
        <v-chip class="m">{{item.id_form_peminjaman}}</v-chip>
      </template>
      <template v-slot:[`item.TanggalPengembalian`]="{ item }">
        <v-chip :color="new Date(TanggalSekarang).valueOf() > new Date(item.tgl_pengembalian).valueOf() ? '#BE7575' : 'grey'" class="m"><span style="color:white">{{item.TanggalPengembalian}}</span></v-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
        class="eye ma-2"
        small  
        @click="openAksi(item)"
        >
          mdi-eye
        </v-icon>
      </template>
      <template v-slot:[`item.approve_peminjaman`]="{ item }">
        <!-- ini untuk hr approve -->
        <v-chip class="ceklis mt-1 mb-1" small v-show="item.acc_hr == null && logged.level == 4" @click="accHRGA(item)">
        <v-icon
        small
        class="icon ma-2"
        @click="accHRGA(item)"
        >
          mdi-check-outline
        </v-icon>
        </v-chip>
        <v-divider v-show="item.acc_hr == null  && logged.level == 4"/>
        <v-chip class="close mt-1 mb-1" small v-show="item.acc_hr == null && logged.level == 4" @click="cancelHRGA(item)">
        <v-icon
        small
        class="icon ma-2"
        >
          mdi-close-outline
        </v-icon>
        </v-chip>
        <v-chip class="chip" v-show="item.acc_hr != null && logged.level < 5 && item.approve_peminjaman == null"><b style="font-size:12px;">HR GA</b><v-icon small>mdi-check</v-icon></v-chip>
        <!-- <v-chip v-show="item.acc_hr != null && logged.level < 5 && item.approve_peminjaman == 'REJECT'"><b style="font-size:12px;">HR GA</b><v-icon small>mdi-close</v-icon></v-chip> -->
        <v-chip class="chip" v-show="item.acc_hr == null && logged.level != 4 && item.approve_peminjaman == null"><b style="font-size:12px;">Menunggu</b></v-chip>
        <!-- end of hr approve -->
  
        <!-- ini untuk manager approve -->
        <v-chip class="ceklis mt-1 mb-1" small v-show="item.acc_hr != null && item.acc_hr != 'N' && item.approve_peminjaman != 'REJECT' && item.acc_manager == null && logged.level == 5" @click="accManager(item)">
        <v-icon
        small
        class="icon ma-2"
        >
          mdi-check-outline
        </v-icon>
        </v-chip>
        <v-divider v-show="item.acc_hr != null && item.acc_hr != 'N' && item.approve_peminjaman != 'REJECT' && item.acc_manager == null  && logged.level == 5"/>
        <v-chip class="close mt-1 mb-1" small v-show="item.acc_hr != null && item.acc_hr != 'N' && item.approve_peminjaman != 'REJECT' && item.acc_manager == null && logged.level == 5" @click="cancelManager(item)">
        <v-icon
        small
        class="icon ma-2"
        >
          mdi-close-outline
        </v-icon>
        </v-chip>
        <!-- end of manager approve -->
        <v-chip class="ceklis" v-show="item.approve_peminjaman == 'ACC'">
        <v-icon
        small
        class="icon ma-2">
          mdi-check-outline
        </v-icon>
        </v-chip>
  
        <v-chip class="close" v-show="item.approve_peminjaman == 'REJECT'">
        <v-icon
        small
        class="icon2 ma-2">
          mdi-close-outline
        </v-icon>
        </v-chip>
      </template>
      
      <template v-slot:[`item.pengembalian`]="{ item }">
      <v-chip class="note mr-2" v-show="item.approve_peminjaman == 'ACC' && (logged.level == 2 || logged.level == 3) && item.pengembalian == null" icon>
      <v-icon
        small
        v-show="item.pengembalian == null"
          @click="ToPengembalian(item)"
          color="#F1F1F6"
        >
          mdi-note-edit
      </v-icon>
      </v-chip>
        <!-- sudah dikembalikan -->
        <v-chip class="notee mr-2" v-show="item.pengembalian != null">
        <v-tooltip left color="black" v-show="item.pengembalian != null">
        <template  v-slot:activator="{ on, attrs }">
        <v-icon
        small
        v-on="on"
        v-bind="attrs"
        v-show="item.pengembalian != null"
        color="#D1D1D1"
        
        >
          mdi-note-check
        </v-icon>
        </template>
        <span>Sudah Dikembalikan</span>
        </v-tooltip>
        </v-chip>
        <!-- sudah dikembalikan complete -->

        <v-chip class="close" v-show="item.approve_peminjaman == 'REJECT'"><v-icon small class="ma-2" color="#F6F6F6">
          mdi-close-outline
        </v-icon>
        </v-chip>
        <v-chip class="chip" v-show="(logged.level == 1 || logged.level == 4 ||  logged.level == 5) && item.approve_peminjaman != 'REJECT' && item.pengembalian == null"><b style="font-size:12px;">Menunggu</b></v-chip>
        <v-chip class="chip" v-show="(logged.level == 2 || logged.level == 3) && item.approve_peminjaman == null"><b style="font-size:12px;">Menunggu</b></v-chip>
      </template>
      <template v-slot:[`item.print`]="{ item }">
        <v-btn class="btn mr-4" small icon>
        <v-icon
          small
          @click="ToPrint(item)"
        >
        mdi-download
        </v-icon>
        </v-btn>
      </template>
      <template v-slot:no-data>
        <v-chip>Mohon Tunggu</v-chip>
      </template>
    </v-data-table>

    <!--button donload mobile-->
    <v-dialog v-model="dialogDownloadPrint">
      <v-card>
      <v-toolbar dark color="#a10115">
        <span>Download Pengajuan Peminjaman</span>
      </v-toolbar>
        <v-row no-gutter class="mx-auto mt-2 mb-2">
          <v-col cols="6">
            <v-btn class="ml-16" @click="DownloadPrint('Y')"><v-icon>mdi-check-outline</v-icon></v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn class="ml-5" @click="DownloadPrint('N')"><v-icon>mdi-close-outline</v-icon></v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAlertManager" width="380px" persistent>
          <v-card>
              <v-toolbar color="#a10115">
                  <span class="text-button" style="color:white;" dark>Tujuan Notif</span>
                  <v-spacer/>
              </v-toolbar>
              <v-container>
                 <v-row>
                      <v-col cols="12" class="mb-n2 mt-4">
                          <v-autocomplete
                              color="#a10115"
                              class="text-button"
                              v-model="SelectPerson"
                              :items="listmanager"
                              dense
                              item-text="nama_lengkap"
                              return-object
                              label="List"
                          ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" class="mt-n4">
                          <v-btn 
                              :disabled="SelectPerson != null ? false : true" 
                              @click="Generate" 
                              width="100%" 
                              :dark="SelectPerson != null ? true : false"
                              color="#a10115">
                              Kirim Notifikasi
                          </v-btn>
                      </v-col>
                 </v-row>
              </v-container>
          </v-card>
      </v-dialog>

      <!-- dialog cancel  -->
      <v-dialog persistent v-model='DialogCancel' width="340px" height="400px">
        <v-card>
          <v-container>
        <v-text-field color="#a10115" label="Alasan" v-model="Alasan">
        </v-text-field>
        <v-btn @click="DoCancelHRGA" :disabled="Alasan == null || Alasan == ''" width="100%" color="#a10115" :dark="Alasan == null || Alasan == '' ? false : true">Simpan</v-btn>
      </v-container>
      </v-card>
      </v-dialog>
    </v-container>
  </v-card>
  </template>
  <script>
  import axios from 'axios'
  import firebase from "@/services/firebase-sw"
  import API from "@/services/http";
    export default {
      data: () => ({
        dialog: false,
        search:'',
        listoken:[],
        SelectPerson:[],
        listmanager:[],
        stored:[],
        Alasan:null,
        DialogCancel:false,
        device:null,
        TanggalSekarang:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        dialogAlertManager:false,
        loading: true,
        logged:[],
        dialogDelete: false,
        headers: [
          { text: 'No Refrensi', value: 'id_form_peminjaman'},
          { text: 'Tanggal Peminjaman', value: 'TanggalPeminjaman'},
          { text: 'Tanggal Kembali', value: 'TanggalPengembalian'},
          { text: 'Jam Keluar', value: 'jam'},
          {
            text: 'Penanggung Jawab',
            align: 'start',
            value: 'penanggung_jawab',
          },
          { text: 'Department', value: 'department.nama_department' },
          { text: 'Model Kendaraan', value: 'aset.nama_aset' },
          { text: 'No Pol', value: 'aset.no_plat' },
          { text: 'Actions', value: 'actions', sortable: false , align: 'start',},
          { text: 'Approve', value:'approve_peminjaman', align:'center'},
          { text: 'Pengembalian', value:'pengembalian', align:'center'},
          { text: 'Berkas', value:'print', align:'center'}
        ],
        dialogDownloadPrint:false,
        desserts: [],
        DataPeminjaman:[],
        editedIndex: -1,
      }),
  
      computed: {
        //
      },
  
      watch: {
        //
      },
  
      created () {
        this.logged = this.$SetLog()
        this.getDataPeminjaman()
      },
      mounted(){
        this.device = this.$device
        this.getManager()
      },
  
      methods: {
          filter (value, search) {
          search = search.toString().toLocaleLowerCase()
          return value != null &&
            search != null &&
            typeof value === 'string' &&
            value.toString().toLocaleLowerCase().indexOf(search) !== -1
        },
        getDataPeminjaman(){
          this.Seen()
          API.get("/formpeminjaman").then(x=>{
            x.data.forEach(z => {
              z.TanggalPeminjaman = this.$DateConvert(z.tgl_peminjaman)
              z.TanggalPengembalian = this.$DateConvert(z.tgl_pengembalian)
              z.jam = z.jam_keluar_kendaraan
            });
            this.DataPeminjaman = x.data.reverse()
            this.loading = false
          })
        },
        Seen(){
          API.get('seen/peminjaman/'+this.logged.id_user)
        },
        SetSeen(x){
          console.log(x)
          API.post('setseen/tes_drive',{
            user:this.SelectPerson.id_user
          }).then(x=>{
            console.log(x)
          })
          API.post('setseenhistory',{Nama : x.penanggung_jawab,id_department:x.id_department})
        },
        openAksi(x){
          console.log(x)
          this.$Store(x)
          // const suara = require('../assets/SuaraNotif.mpeg')
          this.$ChangeURL('Peminjaman/'+x.id_form_peminjaman)
        },
        accHRGA(x){
          this.$loading(true)
          x.accby = JSON.parse(localStorage.getItem('logged')).nama_lengkap
          x.title = 'Approve'
          API.put("/approveformpeminjaman/"+x.id_form_peminjaman,{
                AccBy:'HRGA',
                AccHR:x.accby,
                IdAset:x.aset.id_aset,
              }).then(c=>{
            console.log(c)
            this.$loading(false)
            this.SetSeen(x)
            this.NotifUser(x)
            console.log('x adalah',x)
            //this.KirimNotif(x)
            this.stored = x
            this.dialogAlertManager = true
            this.loading = true
            this.getDataPeminjaman()
            this.$Toast('success','Approve By HRGA')
          })
        },
        NotifUser(x){
          let direct = null
          API.get('searchuser/'+x.penanggung_jawab+'/'+x.department.id_department).then(c=>{
            direct = "userlevel/"+c.data.level+"/"+c.data.id_user
            this.KirimNotif(x,direct)
            this.SetNotifIjo(c.data.id_user,'history')
          })
        },
        NotifSecurity(x){
          let direct = null
          API.get('searchuser/Security/11').then(c=>{
            direct = "userlevel/"+c.data.level+"/"+c.data.id_user
            this.KirimNotif(x,direct)
            this.SetNotifIjo(c.data.id_user,'peminjaman')
          })
        },
        async getManager(){
          API.get('user').then(x=>{
            this.userlist = x.data
            let dt = x.data
            let dtf = dt.filter(x => x.level == 5)
            this.listmanager = dtf
          })
        },
        cancelHRGA(x){
          this.$loading(true)
          x.accby = JSON.parse(localStorage.getItem('logged')).nama_lengkap
          x.title = 'Reject'
          this.stored = x
          this.DialogCancel = true
        },
        DoCancelHRGA(){
          let x = this.stored
          this.DialogCancel = false
          API.put("/approveformpeminjaman/"+x.id_form_peminjaman,{
                AccBy:'HRGA',
                AccStatus:'N',
                AccHR:x.accby,
                Approve:'REJECT',
                IdAset:x.aset.id_aset,
                Alasan:this.Alasan,
              }).then(c=>{
              this.$loading(false)
              console.log(c)
              this.NotifUser(x)
             // this.KirimNotif(x)
              this.loading = true
              this.getDataPeminjaman()
              this.$Toast('success','Cancel By HRGA')
          })
        },
        Generate(){
          let direct = "userlevel/"+this.SelectPerson.level+"/"+this.SelectPerson.id_user
          this.SetNotifIjo(this.SelectPerson.id_user,'peminjaman')
          //this.SetSeenManager()
          this.KirimNotif(this.stored,direct)
          this.dialogAlertManager = false
        },
        SetNotifIjo(id_user,data){
          API.get('manageseen/'+data+'/'+id_user).then(x=>{
            console.log(x)
          })
        },
        accManager(x){
          this.$loading(true)
          x.accby = JSON.parse(localStorage.getItem('logged')).nama_lengkap
          x.title = 'Approve'
          API.put("/approveformpeminjaman/"+x.id_form_peminjaman,{
                AccBy:'Manager',
                AccManager:x.accby,
                Approve:'ACC',
                IdAset:x.aset.id_aset,
              }).then(c=>{
            this.$loading(false)
            console.log(c)
            this.NotifUser(x)
            this.NotifSecurity(x)
            //this.KirimNotif(x)
            this.loading = true
            this.getDataPeminjaman()
            this.$Toast('success','Approve By Manager')
          })
        },
        cancelManager(x){
          this.$loading(true)
          x.accby = JSON.parse(localStorage.getItem('logged')).nama_lengkap
          x.title = 'Reject'
          this.stored = x
          this.DialogCancel = true
         
        },
        DoCancelManager(){
          let x = this.stored
          this.DialogCancel = false
          API.put("/approveformpeminjaman/"+x.id_form_peminjaman,{
                AccBy:x.accby,
                AccManager:'N',
                Approve:'REJECT',
                IdAset:x.aset.id_aset,
                Alasan:this.Alasan,
              }).then(c=>{
              this.$loading(false)
              console.log(c)
              this.NotifUser(x)
              //this.KirimNotif(x)
              this.loading = true
              this.getDataPeminjaman()
              this.$Toast('success','Reject By Manager')
          })
        },
        ToPengembalian(x){
          this.$Store(x)
          this.$ChangeURL('FormPengembalianPeminjaman/'+x.id_form_peminjaman)
        },
        ToPrint(x){
          if(this.device == 'Desktop'){
            this.$Store(x)
            this.$ChangeURL('SuratPengajuanPeminjaman/'+x.id_form_peminjaman)
          }else{
            this.dialogDownloadPrint = true
            this.TempStore = x
          }
        },
        DownloadPrint(x){
          if(x == 'Y'){
            this.dialogDownloadPrint = false
            localStorage.setItem('cetak',true)
            this.ChangeURL('SuratPengajuanPeminjaman/'+this.TempStore.id_form_peminjaman)
          }else{
            this.dialogDownloadPrint = false
          }
        },
  
        async KirimNotif(x,direct){
          // let listoken = [];
          const eventref = firebase.database().ref(direct); // HRGA
          let snapshot = await eventref.once('value');
                  let dx = snapshot.val();
                  if(dx){
                    Object.keys(dx).forEach(key => {
                    this.listoken.push(Object.values(dx[key])[0]);
                    });
                  }
          let data = {
            "registration_ids":this.listoken, 
              "notification" : {
                      "title": x.title+" Peminjaman",
                      "body": x.accby+' '+x.title+" Peminjaman Pengajuan dari "+x.penanggung_jawab+" Peminjaman Kendaraan "+x.aset.jenis_aset+' '+x.aset.nama_aset+' '+x.aset.no_plat,
                      "icon": "https://www.honda-indonesia.com/favicon/android-icon-192x192.png",
            },
            "priority":"high"
          }
          axios.post('https://fcm.googleapis.com/fcm/send',data,{
            headers: {
            'Content-Type': 'application/json',
            'Authorization': 'key=AAAARShXKoo:APA91bGI1FeO6Q8eoNOTmKZTp4Fh7nLEkTY-yaXLMnUDi4z2BpFKWnV0SBOL8bYVSjKaDnGA8Te0Aycdmmo_yjZ2WIeDzitUemUOutRoAa6GKeF_J2AIz-oXEDX_YOrTSzV4aWSjVYIh'
            }
          }).then(x=>{
            console.log(x)
          })
        },
      },
    }
  </script>
  <style>
  .pencarian{
    width: 150px;
  }
  .pencarian_desk{
    width:1200px;
  }
  .download{
    color: #43658B !important;
  }
  .btn{
    background-color: #43658B !important;
    color: white !important;
  }
  .eye{
    color: #43658B !important;
  }
  .chip{
    background-color: #FAFF00 !important;
    color: black !important;
  }
  .ceklis{
    background-color: #78B0A0 !important;
  }
  .close{
    background-color: #BE7575 !important;
  }
  .icon2{
    color: #F6F6F6 !important;
  }
  .icon{
    color: #F6F6F6 !important;
  }
  .note{
    background-color: #52575D !important;
  }
  .notee{
    background-color: white !important;
  }
  </style>
  